import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Col, Container, Row } from "react-bootstrap";

const LinksPage = () => {
  return (
    <Layout>
      <Seo
        title="Links"
        // description="Komplementäre Medizin ist ganzheitlich. Sie behandelt den ganzen Menschen – Körper, Geist und Seele – und nicht nur die Symptome."
      />

      <Container className="mt-md-5 py-5">
        <Row>
          <Col md={12} className="d-flex flex-column">
            <h2 className="section-title mb-4">
              Neurobiologie nach Dr. med Klinghardt
            </h2>
            <a
              href="https://www.ink.ag/"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-tertiary my-1"
            >
              INK-Institut für Neurobiologie nach Dr. Klinghardt &gt;
            </a>
            <a
              href="https://www.verein-neurobiologie.ch/"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-quaternary my-1"
            >
              VNS-Verein für Neurobiologie nach Dr. Klinghardt &gt;
            </a>
            <a
              href="http://www.klinghardtacademy.com/"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-quinary my-1"
            >
              Dr. Klinghardt; Vorträge Autismus, Schwermetall, Artikel über
              Schwangerschaft, chronische Erkrankungen, Borreliose &gt;
            </a>
            <a
              href="www.biopure.com"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-tertiary my-1"
            >
              Bezug von Dr. Klinghardt’s Produkte (Deutschland) &gt;
            </a>
            <a
              href="https://kiscience.com/"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-quaternary my-1"
            >
              Bezug von Dr. Klinghardt’s Produkte (England) &gt;
            </a>
          </Col>

          <Col md={12} className="d-flex flex-column pt-5">
            <h2 className="section-title mb-4">
              Klinghardt über Borreliose & Entgiftung von Schwermetallen
            </h2>
            <a
              href="https://underourskin.com/"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-quinary my-1"
            >
              Film über Borreliose &gt;
            </a>
            <a
              href="https://www.youtube.com/watch?v=pVr5PywldOA"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-tertiary my-1"
            >
              Vergiftung mit Schwermetallen: Vortrag von Dr. Klinghardt &gt;
            </a>
          </Col>
          <Col md={12} className="d-flex flex-column pt-5">
            <h2 className="section-title mb-4">Biophotonen / Photonwave</h2>
            <a
              href="https://www.youtube.com/watch?v=Ho1o4a7P4Hg"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-quaternary my-1"
            >
              Prof. Dr. F. A. Popp über Biophotonen und Regulationsmedizin &gt;
            </a>
            <a
              href="https://www.broeckers.com/Popp.htm"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-quinary my-1"
            >
              Artikel: Interview mit Prof. Popp über Biophotone &gt;
            </a>

            <a
              href="https://biophotonservices.com/videos-resources/"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-tertiary my-1"
            >
              Interview mit Johan Boswinkel, Entwickler von Chiren, über
              Biophotonen-Therapie &gt;
            </a>
            <a
              href="https://docplayer.org/24151039-Wie-johan-boswinkel-die-biophotonen-schwache-von-den-zellen-abgestrahlte-lichtwellen-nutzt-um-die-selbstheilungskraefte-des-koerpers-zu-aktivieren.htm"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-quaternary my-1"
            >
              Artikel im Ode-Magazin: Interview mit Johan Boswinkel über das von
              ihm entwickelte Biophotogen-Gerät Chiren &gt;
            </a>
            <a
              href="https://www.youtube.com/watch?v=mhlSROzL59I"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-quinary my-1"
            >
              Dr. med. Dietrich Klinghardt spricht an einem Kongress über
              Lichttherapie - Neue Ansätze & Möglichkeiten &gt;
            </a>
            <a
              href="https://photonwave.be/"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-tertiary my-1"
            >
              Informationen über die Lichttherapie mit dem Photonwave-Gerät &gt;
            </a>
          </Col>

          <Col md={12} className="d-flex flex-column pt-5">
            <h2 className="section-title mb-4">
            Empfohlene Ärzte und Therapeuten
            </h2>
            <a
              href="https://www.drstefanbirrer.ch/Links"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-quaternary my-1"
            >
              Dr. med Stefan Birrer, Praxis für allgemeine und Komplementäre Medizin in Zug &gt;
            </a>
            <a
              href="https://www.drstefanbirrer.ch/Links"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-quinary my-1"
            >
              Roger Kleefeld, Naturheilpraktiker und Klinghardt-Therapeut / In der Praxis von Dr. med Stefan Birrer &gt;
            </a>
            <a
              href="https://www.annetteconzett.ch/"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-tertiary my-1"
            >
              Annette Conzett, Psychotherapeutin / Individuelle Persönlichkeitsentwicklung durch Körperzentrierte Psychotherapie und Systemische Prozess-Therapie &gt;
            </a>
            <a
              href="https://psylance.ch/"
              target="_blank"
              rel="noreferrer"
              className="p-2 bg-quaternary my-1"
            >
              Psylance AG, Dr. Ruth Enzler Denzler, Juristin und Psychologin, Ressourcen Management und Coaching. &gt;
            </a>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col md={6}>
            <StaticImage
              src="../images/blumentopf-cut-footer.png"
              alt=""
              placeholder="tracedSVG"
              layout="fullWidth"
              // formats={["auto", "webp", "avif"]}
              // sizes="50vw"
              // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 576px) calc(540px - 24px), (max-width: 768px) calc(720px - 24px), (max-width: 992px) calc(960px - 24px), (max-width: 1200px) calc(1140px - 24px), calc(1320px - 24px)"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default LinksPage;
